import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import title_image from '../images/titles/technology_services.jpg'


const pageTitle = "Technology Services";
const pageSlug = "technoglogy-services";


class TechnologyServices extends React.Component{
    render(){
      return(
        <Layout>

          <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
            <div className="overlay"></div>
            <div className="container">
              <h1 className="title">{pageTitle}</h1>
              <h6 className="subtitle">We are designing, planning and implementing technology solutions that can resolve any of your business challenges.</h6>
            </div>
          </section>

          <section id="p_technology_services" className="section static-page page-content">
            <div className="container">
              <div className="columns">
                <div className="column is-12 content-area">

                  <p>Innovation continues to affect company behavior and strategy. Our experts estimate that revenue can be significantly boosted, customer services strengthened and all related business practices simplified when IT is used correctly.
                  </p>
                  <h3>We offer a full range of technical products.
                  </h3>
                  <p>Our packages include nearly all types of custom software development, state-of - the-art technologies, strategy, and support for business applications. Our professionals are experts in designing, implementing, preparing and sustaining innovative new technologies.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h4>Technology Services </h4>
                  <p>Together we can design, prepare and develop a solution customized to your needs, using the following:</p>
                  <h5>Data & Analytics </h5>
                  <p>We can help you build and provide qualified and validated insight for your company.
                  </p>
                  <h5>Modern software delivery </h5>
                  <p>With a state-of - the-art software delivery system, you can realize the value of your technical investments while remaining competitive in an ever-changing marketplace.</p>
                  <h5>Enterprise Collaboration </h5>
                  <p>Working with you to link your talents, abilities and ideas. We strive to improve the work of your workers and to create greater satisfaction.</p>
                  <h5>Mobile App Development </h5>
                  <p>With superior mobile app development technology, we strive to help our customers transform and change the nature of their work.</p>
                  <h5>IT Strategy </h5>
                  <p>We understand how your IT capabilities can be transformed into value creating processes.</p>
                  <h5>DevOps </h5>
                  <p>By automating your software delivery to production processes, we allow you to overcome your competition by producing quality more quickly.</p>
                  <h5>Outsourcing </h5>
                  <p>Together we can set up an innovative long-term delivery system for custom or packaged applications management, enhancement and maintenance.</p>
                  <h5>Enterprise Software and Services </h5>
                  <p>We will find the right services for you by integrating our tactical perspective with hands-on ERP and CRM tools.</p>
                  <h5>Sales Force Management</h5>
                  <p>Our experts will help you develop and use any Salesforce services to their full potential, tools that provide application development, CRM, and marketing automation.</p>
                  <h5>Cloud computing </h5>
                  <p>We will give you a wide range of solutions by working together to improve and automate all of your business processes.</p>
                  <h5>Microsoft & Google Suite</h5>
                  <p>We have ample experience and exposure with Suite apps and have comprehensive skills in any field from managing infrastructure to developing applications.</p>
                  <h5>We can help you find your solution</h5>
                  <p>Let us work together to make your business, technology, and digital services succeed, with our efficiency, expert knowledge, and experience we can make your business plan a success. </p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <hr />
                  <h3>Through partnering with Monera Technologies we will find a solution that works for you. </h3>
                  <p>Discover your true potential.</p>  
                  <p><a href="">Let's get to work.</a></p>





                </div>
              </div>
            </div>
          </section>



        </Layout>
    )
  }
}

export default TechnologyServices
